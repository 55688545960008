<template>
	<div>
		<!-- Page Header -->
		<div class="text-center py-16">
			<h3 class="text-h4 font-weight-light mb-5">Under Construction</h3>

			<v-btn outlined color="primary" large @click="goHome()">Go Home</v-btn>
		</div>
	</div>
</template>

<script>
	export default {
		data: () => ({
		}),
	
		methods: {
			goHome() {
				this.$router.push({ name: 'home' })
			}
		}
	}
</script>